import React, { useState, useEffect } from "react";
import { Box, Grid } from "grommet";

import Footer from "./room/Footer";
import SideBar from "./room/SideBar";
import RoomStatus from "./room/RoomStatus";
import RoomHeader from "./room/RoomHeader";
import { format } from 'date-fns'
import { useSSE } from "react-hooks-sse";


const RoomScreen = props => {
    const { state, api, getSettings } = props;
    const [meetingView, setMeetingView] = useState(0);
    const [tab, setTab] = useState(0);
    const [tSet, setTSet] = useState(false)
    const [dateState, setDateState] = useState(new Date())
    const [endTimeState, setEndTimeState] = useState(new Date())
    const [extend, setExtend] = useState();
    const [newBooking, setNewBooking] = useState();
    const newData = useSSE("message", state);
    const [unAvailable, setUnAvailable] = useState(null)

    const [agenda, setAgenda] = useState(
        state.agenda.filter(
            incomplete =>
                Date.parse("01/01/2011 " + incomplete.end) >
                Date.parse("01/01/2011 " + new Date().toLocaleTimeString())
        )
    );
    const [currentMeeting, setCurrentMeeting] = useState(
        agenda.filter(
            active =>
                Date.parse("01/01/2011 " + active.start) <
                Date.parse("01/01/2011 " + new Date().toLocaleTimeString())
        )[0]
    );
    const [stateColour, setStateColour] = useState(
        !currentMeeting ? state.layout.vacantColour : state.layout.occupiedColour
    );

    useEffect(
        () => {
            setStateColour(!currentMeeting ? state.layout.vacantColour : state.layout.occupiedColour);
        },
        [currentMeeting]
    );

    useEffect(
        () => {
            setCurrentMeeting(
                agenda.filter(
                    active =>
                        Date.parse("01/01/2011 " + active.start) <=
                        Date.parse("01/01/2011 " + new Date().toLocaleTimeString())
                )[0]
            );
        },
        [JSON.stringify(agenda)]
    );

    useEffect(
        () => {
            const timer = setInterval(() => {
                if (!currentMeeting) {
                    setCurrentMeeting(
                        agenda.filter(
                            active =>
                                Date.parse("01/01/2011 " + active.start) <=
                                Date.parse("01/01/2011 " + new Date().toLocaleTimeString())
                        )[0]
                    );
                }
            }, 10000);

            return () => clearInterval(timer);
        },
        [agenda]
    );

    
    useEffect(
        () => {
            const newAgenda = newData.agenda.filter(
                incomplete =>
                    Date.parse("01/01/2011 " + incomplete.end) >
                    Date.parse("01/01/2011 " + new Date().toLocaleTimeString()));

            if (
                state.signedIn &&
                JSON.stringify(newAgenda) != JSON.stringify(state.agenda)
            ) {
                setAgenda(newAgenda);
            }
        },
        [newData]
    );

    function endCurrentMeeting() {
        setAgenda(agenda.filter(agenda => agenda !== currentMeeting));
        api.EndBooking(currentMeeting.id, currentMeeting.isBooking).then(result => {
            getSettings()});
        setCurrentMeeting(null);
    }

    function startMeeting(index) {
        agenda[index].start = new Date().toLocaleTimeString();
        setAgenda(agenda);
        api.StartBooking(agenda[index].id, agenda[index].isBooking).then(result => {
            getSettings()});
    }

    function extendMeeting() {
        const index = agenda.findIndex(
            element => element.id === extend.id
        );
        agenda[index].start = format(dateState, 'k:mm');
        agenda[index].end = format(endTimeState, 'k:mm');
        setAgenda(agenda);
        if (currentMeeting && extend.description === currentMeeting.description) {
            setCurrentMeeting(agenda[index]);
        }
        setExtend(null);
        setTab(0)
        api.ExtendBooking(agenda[index].id, format(endTimeState, 'k:mm'), agenda[index].isBooking).then(result => {
            getSettings()});
    }

    function saveBooking() {
        const bookingObj = {
            source: state.widgetKey,
            occasionId: state.occasionId,
            enquiryContact: {
                exitstingContactId: newBooking.contactId,
            },
            venueId: state.resources[0].resource.venueId,
            bookingDate: newBooking.date,
            bookingType: "fullHire",
            startTime: newBooking.start,
            endTime: newBooking.end,
            bookingEndDate: newBooking.date,
            numberOfGuests: 1,
            isPublicEvent: true,
            publicEventDescription: newBooking.description,
            notes: "",
            details: [
                {
                  resourceId: state.resources[0].resource.id,
                  resourceType: state.resources[0].resource.resourceType,
                  hireType: "fullHire",
                  quantity: 1,
                }
            ],
        }
       
        api.checkAvailability(bookingObj).then(
            (result) => {
                console.log(result);
            if (result.state === "pending"){
                console.log("Pending...")
            }
            if (result.state === "notAvailable") {
                setUnAvailable(result);
            }
            if (result.state === "available") {
                api.requestBooking(bookingObj).then(result => {
                    agenda.push({
                        ...newBooking, 
                        start: format(dateState, 'k:mm'),
                        end: format(endTimeState, 'k:mm'),
                        date: format(dateState, 'dd/MM/yyyy')
                    });
                    setAgenda(agenda);
                    setNewBooking(null);
                    setTab(0)
                    getSettings()
                });
                
                
                
            }
        })
    }

    function cancelBooking(booking) {
        setAgenda(agenda.filter(b => b !== booking));
        api.AbandonBooking(booking.id, booking.isBooking);
    }

    return (
        <Box fill>
            <RoomHeader stateColour={stateColour} state={state} newBooking={newBooking} extend={extend} />
            <Grid
                rows={["flex", "xsmall"]}
                columns={["1/4", "3/4"]}
                areas={[["sidebar", "main"], ["footer", "footer"]]}
                fill
            >
                <SideBar
                    stateColour={stateColour}
                    meetingView={meetingView}
                    setMeetingView={setMeetingView}
                    agenda={agenda}
                    currentMeeting={currentMeeting}
                    setExtend={setExtend}
                    endCurrentMeeting={endCurrentMeeting}
                    startMeeting={startMeeting}
                    cancelBooking={cancelBooking}
                    state={state}
                />
                <RoomStatus
                    stateColour={stateColour}
                    currentMeeting={currentMeeting}
                    agenda={agenda}
                    state={state}

                    setExtend={setExtend}
                    extendMeeting={extendMeeting}
                    extend={extend}

                    setNewBooking={setNewBooking}
                    saveBooking={saveBooking}
                    newBooking={newBooking}

                    tab={tab}
                    setTab={setTab}

                    dateState={dateState}
                    setDateState={setDateState}
                    endTimeState={endTimeState}
                    setEndTimeState={setEndTimeState}
                    tSet={tSet}
                    setTSet={setTSet}
                    unAvailable={unAvailable}
                    setUnAvailable={setUnAvailable}

                    api={api}
                />
                <Footer
                    stateColour={stateColour}
                    state={state}
                    currentMeeting={currentMeeting}
                    setNewBooking={setNewBooking}
                    newBooking={newBooking}
                    setExtend={setExtend}
                    extend={extend}
                    setTSet={setTSet}
                    setTab={setTab}
                />
            </Grid>
        </Box>
    );
};

export default RoomScreen;
