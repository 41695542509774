import React from 'react'
import {
    Button,
    Box,
    Grid
} from 'grommet'

const SideBarBookingButtons = (props) => {
    const columns = {
        xsmall: ['100%'],
        small: ['1/2', '1/2'],
        medium: ['1/3', '1/3', '1/3']
    }
    const rows = {
        xsmall: ['70px', '70px', '70px'],
        small: ['70px', '70px'],
        medium: ['70px'],
    }
    const areas = {
        xsmall: [
            { name: 'btn3', start: [0, 0], end: [0, 0] },
            { name: 'btn4', start: [0, 1], end: [0, 1] },
            { name: 'btn5', start: [0, 2], end: [0, 2] },
        ],
        small: [
            { name: 'btn3', start: [0, 0], end: [0, 0] },
            { name: 'btn4', start: [1, 0], end: [1, 0] },
            { name: 'btn5', start: [0, 1], end: [1, 1] },
        ],
        medium: [
            { name: 'btn3', start: [0, 0], end: [0, 0] },
            { name: 'btn4', start: [1, 0], end: [1, 0] },
            { name: 'btn5', start: [2, 0], end: [2, 0] },
        ],
        twobtnxsmall: [
            { name: 'btn1', start: [0, 0], end: [0, 0] },
            { name: 'btn2', start: [0, 1], end: [0, 1] },
        ],
        twobtnmedium: [
            { name: 'btn1', start: [0, 0], end: [0, 0] },
            { name: 'btn2', start: [1, 0], end: [1, 0] },
        ]
    }
    const { state, booking, currentMeeting, setExtend, endCurrentMeeting, startMeeting, index, cancelBooking, ScreenSizeNum } = props;

    if (booking === currentMeeting) {
        return (
            <Grid
                rows={(ScreenSizeNum <= 1) ? rows.small : rows.medium}
                columns={(ScreenSizeNum <= 1) ? columns.xsmall : columns.small}
                pad="none"
                areas={(ScreenSizeNum <= 1) ? areas.twobtnxsmall : areas.twobtnmedium}
                fill="horizontal"
            >
                {state.canExtend && <Box gridArea="btn1">
                    <Button
                        margin="small"
                        primary
                        label="Extend"
                        onClick={() => setExtend(booking)}
                    />
                </Box>}
                {state.canEnd && <Box gridArea="btn2">
                    <Button
                        margin="small"
                        primary
                        label="End"
                        onClick={endCurrentMeeting}
                    />
                </Box>}
            </Grid>
        )
    }

    return (
        <Grid
            rows={(ScreenSizeNum <= 1) ? rows.xsmall : (ScreenSizeNum === 2 ? rows.small : rows.medium)}
            columns={(ScreenSizeNum <= 1) ? columns.xsmall : (ScreenSizeNum === 2 ? columns.small : columns.medium)}
            pad="none"
            areas={(ScreenSizeNum <= 1) ? areas.xsmall : (ScreenSizeNum === 2 ? areas.small : areas.medium)}
            fill="horizontal"
        >
            {state.canStart && <Box gridArea="btn3">
                <Button
                    margin="small"
                    primary
                    label="Start"
                    disabled={currentMeeting ? true : false}
                    onClick={() => startMeeting(index)}
                />
            </Box>}
            {state.canExtend && <Box gridArea="btn4">
                <Button
                    margin="small"
                    primary
                    label="Extend"
                    onClick={() => setExtend(booking)}
                />
            </Box>}
            {state.canCancel && <Box gridArea="btn5">
                <Button
                    margin="small"
                    primary
                    label="Cancel"
                    onClick={() => cancelBooking(booking)}
                />
            </Box>}
        </Grid>
    )

}

export default SideBarBookingButtons;